var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasRecentlyViewedProducts)?_c('UCCardSection',{staticClass:"recently-viewed-products-carousel",attrs:{"title":_vm.title,"description":_vm.description,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: '218px',
    showDescription: false,
    showSteppers: false,
  },"tablet":{
    cardFlexBasis: '25%',
    showDescription: true,
    showSteppers: true,
  },"desktop":{
    cardFlexBasis: '20%',
  }}},_vm._l((_vm.recentlyViewedProducts),function(product,i){return _c('UCProductCard',{key:i,attrs:{"product-id":_vm.productGetters.getId(product),"image-src":_vm.productGetters.getCoverImage(product),"brand":_vm.productGetters.getVendorName(product),"brand-link":_vm.getBrandLinkByVendor(_vm.brandsAsArray, _vm.productGetters.getProductVendor(product)),"title":_vm.productGetters.getName(product),"sku":_vm.productGetters.getSKU(product),"price":_vm.productGetters.getPrice(product),"compare-to-price":_vm.productGetters.getCompareToPrice(product),"in-stock":Boolean(_vm.productGetters.getStock(product)),"link":("/products/" + (_vm.productGetters.getSlug(product))),"mode":"mini","availability":_vm.productGetters.getProductAvailability(product),"variant-id":_vm.productGetters.getVariantId(product),"part-number":_vm.productGetters.getProductVariantMetafield(product, 'partNumber')}})}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }