/* istanbul ignore file */
import { computed, Ref } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';
import { Location } from 'vue-router';

import useUiHelpers from '../../useUiHelpers';
import {
  FilterSetupConfig,
  IUsePathParamFilters,
  RouteFilterOption,
  RouteFilters,
  UsePathParamFiltersRefs,
} from './types';

const usePathParamFilters = (cacheId: string): IUsePathParamFilters => {
  const { getCurrentRouteParams, getCurrentRoute, getCurrentQuery } = useUiHelpers();

  const filters: Ref<RouteFilters> = sharedRef({}, `${cacheId}-UsePathParamFilters`);

  const basePath: Ref<string> = sharedRef('', `${cacheId}-UsePathParamFilters/basePath`);

  const setBasePath = (newBasePath: string) => {
    basePath.value = newBasePath;
  };

  const vueRouterDynamicParamLabel: Ref<string> = sharedRef(
    'filter',
    `${cacheId}-UsePathParamFilters/vueRouterDynamicParamLabel`,
  );

  const setVueRouterDynamicParamLabel = (newVueRouterDynamicParamLabel: string) => {
    vueRouterDynamicParamLabel.value = newVueRouterDynamicParamLabel;
  };

  const filterOrder: Ref<string[]> = sharedRef([], `${cacheId}-UsePathParamFilters/FilterOrder`);

  const setFilterOrder = (order: string[]) => {
    filterOrder.value = order;
  };

  const refs: UsePathParamFiltersRefs = {
    filters,
  };

  // do a search through all possible filter options and match up the filter slugs to return the actives
  const activeFilters = computed(() => {
    const currentParams = getCurrentRouteParams();
    if (!currentParams) {
      return [];
    }

    const filterSlugs = currentParams[vueRouterDynamicParamLabel.value]?.split('/') ?? [];
    const filterGroups = Object.keys(filters.value);
    const allOptions: RouteFilterOption[] = [];
    filterGroups.forEach((group) => {
      allOptions.push(...(refs.filters.value[group]?.options ?? []));
    });
    const activeFilters: RouteFilterOption[] = [];
    allOptions.forEach((option) => {
      if (option.slug && filterSlugs.includes(option.slug)) {
        activeFilters.push(option);
      }
    });

    return activeFilters;
  });

  const setup = (refs: UsePathParamFiltersRefs) => (groups: Record<string, FilterSetupConfig>) => {
    Object.entries(groups).forEach(([groupLabel, conf]) => {
      refs.filters.value[groupLabel] = {
        groupLabel: groupLabel,
        label: conf.label,
        target: conf.target,
        options: [],
      };
    });
  };

  const setOptions =
    (refs: UsePathParamFiltersRefs) => (param: string, options: Partial<RouteFilterOption>[]) => {
      options.forEach((option) => {
        option.target = refs.filters.value[param].target;
        option.groupLabel = param;
      });
      refs.filters.value[param].options = options as RouteFilterOption[];
    };

  const sortOptionsByFilterOrder = (filters: RouteFilterOption[]) =>
    filters.sort((a, b) => {
      return (
        filterOrder.value.findIndex((groupLabel) => a.groupLabel === groupLabel) -
        filterOrder.value.findIndex((groupLabel) => b.groupLabel === groupLabel)
      );
    });

  const generateRouteLocation = (filters: RouteFilterOption[]): Partial<Location> | undefined => {
    const route = { ...getCurrentRoute() };
    if (!route) return;
    sortOptionsByFilterOrder(filters);
    const path = [basePath.value];
    // appends the group label as the first segment. /author/filter, /category/filter etc..
    if (filters.length > 0) {
      path.push(filters[0].groupLabel);
    }
    const slugs = filters.map((filter) => filter.slug);

    let queryParams = getCurrentQuery();

    if (queryParams) {
      const { page: _, ...queryParamsWithoutPage } = queryParams;
      queryParams = queryParamsWithoutPage;
    }

    return {
      path: [...path, ...slugs].join('/'),
      query: queryParams,
    };
  };

  const getAddFilterURL = (incomingFilter: RouteFilterOption): Partial<Location> | undefined => {
    const currentFilters = [...activeFilters.value];
    const filteredFilters = currentFilters.filter((filterOption) => {
      return filterOption.target != incomingFilter.target;
    });
    const newFilters = [...filteredFilters, incomingFilter];
    return generateRouteLocation(newFilters);
  };

  const getRemoveFilterURL = (outgoingFilter: RouteFilterOption): Partial<Location> | undefined => {
    const currentFilters = [...activeFilters.value];
    const filteredFilters = currentFilters.filter((filterOption) => {
      return filterOption.target !== outgoingFilter.target;
    });
    const newFilters = [...filteredFilters];
    return generateRouteLocation(newFilters);
  };

  const getClearFilterURL = (): Partial<Location> | undefined => {
    return generateRouteLocation([]);
  };

  const getFilterLabel = (filterOption: RouteFilterOption) => {
    if (!filterOption) return undefined;
    if (filterOption?.label) return filterOption.label;
    let filterLabel: string | undefined;
    const params = Object.keys(filters.value);
    params.forEach((param) =>
      filters.value[param]?.options?.forEach((option: RouteFilterOption) => {
        if (option.value === filterOption.value) {
          filterLabel = option.label;
        }
      }),
    );
    return filterLabel;
  };

  return {
    setup: setup(refs),
    setOptions: setOptions(refs),
    filters: computed(() => filters.value),
    activeFilters,
    getFilterLabel,
    getAddFilterURL,
    getRemoveFilterURL,
    getClearFilterURL,
    setBasePath,
    setVueRouterDynamicParamLabel,
    setFilterOrder,
  };
};

export default usePathParamFilters;
