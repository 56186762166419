import { buildURL } from '~/helpers/videoEmbeddings/youtube';

export interface IMediaPage {
  content: {
    component: 'ArticlePage' | 'VideoPage';
    youtube_video?: {
      content: {
        youtube_video_id: string;
      };
    };
    title: string;
    media_category: {
      name: string;
    };
    author: {
      name: string;
    };
    description: string;
    banner_image: {
      filename: string;
      alt: string;
    };
  };
  full_slug: string;
  _meta?: {
    full_slug: string;
  };
}

export function mapToVideoMediaCard(item: IMediaPage) {
  return {
    videoSrc: buildURL(item.content?.youtube_video?.content?.youtube_video_id ?? ''),
    title: item.content?.title ?? '',
    description: item.content?.description ?? '',
    author: item.content?.author?.name ?? '',
    link: (item?.full_slug || item?._meta?.full_slug) ?? '',
    showLinkText: true,
  };
}

export function mapToImageMediaCard(item: IMediaPage) {
  return {
    imageSrc: item.content?.banner_image?.filename ?? '',
    imageAlt: item.content?.banner_image?.alt ?? '',
    tag: item.content?.media_category?.name ?? '',
    title: item.content?.title ?? '',
    description: item.content?.description ?? '',
    author: item.content?.author?.name ?? '',
    link: (item?.full_slug || item?._meta?.full_slug) ?? '',
    showLinkText: true,
  };
}
