import { render, staticRenderFns } from "./LatestVideoCard.vue?vue&type=template&id=086d8434&scoped=true&"
import script from "./LatestVideoCard.vue?vue&type=script&lang=ts&"
export * from "./LatestVideoCard.vue?vue&type=script&lang=ts&"
import style0 from "./LatestVideoCard.vue?vue&type=style&index=0&id=086d8434&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "086d8434",
  null
  
)

export default component.exports