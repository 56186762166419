<template>
  <div id="vehicles">
    <BaseSidebar
      :visible="isVehicleSelectionSidebarOpen || isGarageSelectionSidebarOpen"
      class="sf-sidebar--right"
      @close="toggleSidebar"
    >
      <transition name="sf-fade" mode="out-in">
        <div class="vehicles-sidebar">
          <div class="vehicles-sidebar-header">
            <i class="fa-solid fa-garage-car vehicles-sidebar-header-icon"></i>
            <div class="vehicles-sidebar-header-content">
              <h4 class="vehicles-sidebar-header-content-title">My Vehicles</h4>
              <div class="vehicles-sidebar-header-content-desc">
                Find the parts designed to fit your vehicle.
              </div>
            </div>
          </div>
          <VehicleSelection v-if="isVehicleSelectionSidebarOpen" @hide-sidebar="toggleSidebar" />
          <GarageSelection v-if="isGarageSelectionSidebarOpen" v-on="$listeners" />
        </div>
      </transition>
    </BaseSidebar>
  </div>
</template>
<script>
import { defineComponent, onMounted, useRoute } from '@nuxtjs/composition-api';
import { BaseSidebar } from '@unified-commerce/gpc-ui-library';

import GarageSelection from '~/components/Garage/GarageSelection/GarageSelection.vue';
import VehicleSelection from '~/components/VehicleSelection/VehicleSelection.vue';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'VehiclesSideBar',
  components: {
    BaseSidebar,
    VehicleSelection,
    GarageSelection,
  },
  setup() {
    const route = useRoute();
    const {
      isVehicleSelectionSidebarOpen,
      toggleVehicleSelectionSidebar,
      isGarageSelectionSidebarOpen,
      toggleGarageSelectionSidebar,
    } = useUiState();

    onMounted(() => {
      if (route.value?.hash === '#rego') {
        toggleVehicleSelectionSidebar();
      }
    });

    const toggleSidebar = () => {
      isVehicleSelectionSidebarOpen.value
        ? toggleVehicleSelectionSidebar()
        : toggleGarageSelectionSidebar();
    };

    return {
      isVehicleSelectionSidebarOpen,
      toggleVehicleSelectionSidebar,
      isGarageSelectionSidebarOpen,
      toggleGarageSelectionSidebar,
      toggleSidebar,
    };
  },
});
</script>

<style lang="scss" scoped>
#vehicles {
  --sidebar-z-index: var(--modal-z-index);
  --overlay-z-index: var(--modal-z-index);
  --sidebar-content-padding: 0;
  --sidebar-circle-icon-display: flex;
  @include md-devices-min {
    & > * {
      --sidebar-bottom-padding: var(--spacer-base);
      --sidebar-width: 38.5rem;
      --sidebar-circle-icon-display: none;
      --sidebar-content-padding: 0;
      --sidebar-left: auto;
    }
  }

  ::v-deep .desktop-only {
    display: unset !important;
  }
  ::v-deep .sf-bar {
    display: none;
  }

  ::v-deep .sf-sidebar__circle-icon {
    top: unset;
    transform: unset;
    --button-border-radius: 0;
    --button-box-shadow: unset;
    --button-background: var(--c-background-variant);
    --icon-color: black;
    --button-padding: var(--spacer-sm);
    --button-size: unset;
  }
}

.vehicles-sidebar {
  ::v-deep .sf-collected-product__quantity-wrapper {
    position: unset;
  }

  ::v-deep .sf-collected-product__actions {
    margin: var(--spacer-3sm) 0 var(--spacer-2lg) 0;
  }

  &-header {
    &-icon {
      padding-left: var(--spacer-sm);
      font-size: 24px;
    }

    background-color: var(--c-background-variant);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: var(--spacer-3sm);
    padding: var(--spacer-3xs) 0;
    @include md-devices-min {
      padding: var(--spacer-3sm) 0;
    }
    &-content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-content: center;
      padding-right: var(--spacer-sm);

      @include md-devices-min {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        column-gap: var(--spacer-4base);
      }

      &-title {
        font-weight: var(--font-weight--bold);
        font-size: var(--font-size--2base);
        letter-spacing: -0.04em;
        text-transform: capitalize;
      }

      &-desc {
        font-weight: var(--font-weight--normal);
        font-size: var(--font-size--base);
      }
    }
  }

  svg {
    width: var(--jeep-icon-width, 53px);
    height: var(--jeep-icon-height, 42px);
  }
}
</style>
