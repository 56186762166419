var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UCCardSection',{staticClass:"category-carousel",attrs:{"title":_vm.title,"description":_vm.description,"link":_vm.linkUrl,"link-text":_vm.link_text,"separator":_vm.separator,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: '150px',
    showDescription: false,
    showSteppers: false,
  },"tablet":{
    cardFlexBasis: '25%',
    showDescription: true,
    showSteppers: true,
  },"desktop":{
    cardFlexBasis: '20%',
  }}},_vm._l((_vm.categories),function(category,i){return _c('CategoryCard',{key:i,attrs:{"title":category.content.name,"image-src":category.content.thumb_image.filename,"image-alt":category.content.thumb_image.alt,"link":_vm.getCategoryLink(category)}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }