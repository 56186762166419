var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.cart)?_c('div',{attrs:{"id":"cart"}},[_c('BaseSidebar',{directives:[{name:"e2e",rawName:"v-e2e",value:('sidebar-cart'),expression:"'sidebar-cart'"}],staticClass:"sf-sidebar--right",attrs:{"visible":_vm.isCartSidebarOpen},on:{"close":_vm.toggleCartSidebar},scopedSlots:_vm._u([{key:"content-bottom",fn:function(){return [_c('transition',{attrs:{"name":"sf-fade"}},[_c('div',[(_vm.cartGetters.getTotalItems(_vm.cart))?_c('div',[_c('p',{staticClass:"my-cart__next-step"},[_vm._v("Shipping & discounts calculated at the next step")]),_vm._v(" "),_c('BaseProperty',{staticClass:"sf-property--full-width sf-property--large my-cart__savings",attrs:{"name":"Your savings"},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('BasePrice',{attrs:{"regular":_vm.$n(_vm.calculateSavings(), 'currency')}})]},proxy:true}],null,false,1786974431)}),_vm._v(" "),_c('BaseProperty',{staticClass:"sf-property--full-width sf-property--large my-cart__total-price",attrs:{"name":"Subtotal"},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('BasePrice',{attrs:{"regular":_vm.$n(_vm.cartGetters.getTotals(_vm.cart).subtotal, 'currency')}})]},proxy:true}],null,false,1736918571)}),_vm._v(" "),_c('BaseButton',{staticClass:"sf-button--full-width color-secondary sf-proceed_to_checkout cart-sidebar-checkout",attrs:{"aria-label":"Proceed to secure checkout"},on:{"click":function($event){_vm.toggleCartSidebar();
                _vm.handleCheckout();}}},[_c('i',{staticClass:"fa-solid fa-lock-keyhole cart-sidebar-checkout-icon"}),_vm._v("\n              "+_vm._s('Secure Checkout')+"\n            ")])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"cart-sidebar-shopping-btn"},[_c('BaseButton',{staticClass:"sf-button--full-width",attrs:{"aria-label":"Continue shopping"},on:{"click":_vm.toggleCartSidebar}},[_vm._v(_vm._s('Continue Shopping')+"\n            ")])],1)])])]},proxy:true}],null,false,593721060)},[_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[_c('div',{staticClass:"cart-sidebar"},[_c('div',{staticClass:"cart-sidebar-header"},[_c('i',{staticClass:"fa-solid fa-cart-shopping cart-sidebar-header-icon"}),_vm._v(" "),_c('div',{staticClass:"vehicles-sidebar-header-content"},[_c('h4',{staticClass:"cart-sidebar-header-content-title"},[_vm._v("My Cart")]),_vm._v(" "),_c('div',{staticClass:"cart-sidebar-header-content-desc"},[_vm._v("\n              Review your cart and proceed to checkout\n            ")])])]),_vm._v(" "),(_vm.cartGetters.getTotalItems(_vm.cart))?_c('div',{key:"my-cart",staticClass:"my-cart"},[_c('div',{staticClass:"collected-product-list"},[_c('transition-group',{attrs:{"name":"sf-fade","tag":"div"}},_vm._l((_vm.cartGetters.getItems(_vm.cart)),function(product){return _c('BaseCollectedProduct',{directives:[{name:"e2e",rawName:"v-e2e",value:('collected-product'),expression:"'collected-product'"}],key:_vm.cartGetters.getItemSku(product),class:_vm.collectedProductClass(product),attrs:{"image":_vm.cartGetters.getItemImage(product),"title":_vm.cartGetters.getItemName(product),"regular-price":_vm.$n(_vm.cartGetters.getPrice(product), 'currency'),"special-price":_vm.cartGetters.getCompareToPrice(product) &&
                  _vm.$n(_vm.cartGetters.getCompareToPrice(product), 'currency'),"stock":99999,"has-remove":false},on:{"click:remove":function($event){return _vm.removeFromCart(product)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"collected-product-brand"},[_vm._v(_vm._s(_vm.cartGetters.getVendor(product)))]),_vm._v(" "),_c('h3',{staticClass:"collected-product-title"},[_vm._v(_vm._s(_vm.cartGetters.getItemName(product)))])]},proxy:true},{key:"configuration",fn:function(){return [_c('p',{staticClass:"collected-product-part-number"},[_vm._v("\n                    "+_vm._s(_vm.cartGetters.getPartNumber(product))+"\n                  ")]),_vm._v(" "),_c('div',{staticClass:"collected-product-price-quantity-block"},[_c('div',{staticClass:"sf-collected-product__quantity-wrapper"},[_c('BaseQuantitySelector',{staticClass:"sf-collected-product__quantity-selector",attrs:{"disabled":_vm.loading,"qty":_vm.cartGetters.getCartItemQuantity(product),"max":_vm.cartGetters.getCartItemMaximumQuantity(product, {
                            supportFreeSale: true,
                          })},on:{"input":function($event){_vm.updateQuantity({ product: product, quantity: Number($event) })}}})],1),_vm._v(" "),_c('div',{staticClass:"collected-product-price"},[(_vm.cartGetters.getCompareToPrice(product))?_c('span',{staticClass:"collected-product-price-special"},[_vm._v("\n                        "+_vm._s(_vm.$n(_vm.cartGetters.getCompareToPrice(product), 'currency'))+"\n                      ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"collected-product-price-regular",class:{
                          'collected-product-price-regular-obsolete':
                            _vm.cartGetters.getCompareToPrice(product),
                        }},[_vm._v("\n                        "+_vm._s(_vm.$n(_vm.cartGetters.getPrice(product), 'currency')))])])]),_vm._v(" "),_c('div',{staticClass:"collected-product-remove"},[_c('BaseButton',{staticClass:"collected-product-remove__button",attrs:{"aria-label":("Remove " + (product.title) + " from cart")},on:{"click":function($event){return _vm.removeFromCart(product)}}},[_c('i',{staticClass:"fa-solid fa-trash collected-product-remove-icon"})])],1)]},proxy:true}],null,true)})}),1)],1)]):_c('div',{key:"empty-cart",staticClass:"empty-cart"},[_c('div',{staticClass:"empty-cart__banner"},[_c('BaseImage',{staticClass:"empty-cart__image",attrs:{"alt":"Empty bag","src":"/icons/empty-cart.svg","width":211,"height":143}}),_vm._v(" "),_c('BaseHeading',{staticClass:"empty-cart__heading",attrs:{"title":"Your cart is empty","level":2,"description":"Looks like you haven’t added any items to the bag yet. Start\n            shopping to fill it in."}})],1)])])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }