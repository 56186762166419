import { ISbRichtext, renderRichText, RichTextResolver, RichTextSchema } from '@storyblok/js';
import compact from 'lodash/compact';
import sanitizeHtml from 'sanitize-html';

const resolver = new RichTextResolver();

const sanitizerOptions = {
  allowedTags: [
    'p',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'ul',
    'ol',
    'li',
    'quote',
    'a',
    'img',
    'b',
    'i',
    's',
    'u',
    'sup',
    'sub',
  ],
  allowedAttributes: {
    a: ['href', 'target'],
    img: ['src', 'alt'],
  },
};

export const extractRichTextItems = (richText: ISbRichtext) => {
  const items: Array<string> = [];

  richText?.content?.forEach((item) => {
    let result;

    switch (item.type) {
      case 'blok':
        result = item?.attrs?.body ?? [];
        break;
      default:
        result = sanitizeHtml(
          renderRichText(
            { type: 'doc', content: [item] },
            {
              schema: RichTextSchema,
            },
            resolver,
          ),
          sanitizerOptions,
        );
    }

    if (typeof result === 'string' && typeof items[items.length - 1] === 'string') {
      items[items.length - 1] += result;
    } else {
      items.push(result);
    }
  });

  return compact(items);
};
