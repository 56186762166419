import Vue from 'vue';

import AmbassadorProductGrid from '~/components/CMS/AmbassadorProductGrid/AmbassadorProductGrid.vue';
import AnchorLinkedBlocks from '~/components/CMS/AnchorLinkedBlocks/AnchorLinkedBlocks.vue';
import ArticleGrid from '~/components/CMS/ArticleGrid/ArticleGrid.vue';
import BlogPostTitle from '~/components/CMS/BlogPostTitle';
import BrandCarousel from '~/components/CMS/BrandCarousel/BrandCarousel';
import CategoryCarousel from '~/components/CMS/CategoryCarousel/CategoryCarousel';
import CategoryHeader from '~/components/CMS/CategoryHeader/CategoryHeader.vue';
import ContactForm from '~/components/CMS/ContactForm/ContactForm';
import CustomerValueProposition from '~/components/CMS/CustomerValueProposition/CustomerValueProposition';
import CustomerValuePropositionItem from '~/components/CMS/CustomerValuePropositionItem/CustomerValuePropositionItem';
import FAQList from '~/components/CMS/FAQList';
import FeaturedArticleBanner from '~/components/CMS/FeaturedArticleBanner/FeaturedArticleBanner';
import FeaturedContent from '~/components/CMS/FeaturedContent/FeaturedContent';
import FeaturedMediaCarousel from '~/components/CMS/FeaturedMediaCarousel/FeaturedMediaCarousel.vue';
import FeaturedMediaGrid from '~/components/CMS/FeaturedMediaGrid/FeaturedMediaGrid.vue';
import FeaturedProductBanner from '~/components/CMS/FeaturedProductBanner/FeaturedProductBanner';
import FeaturedYoutubeVideo from '~/components/CMS/FeaturedYoutubeVideo/FeaturedYoutubeVideo';
import HeroCarousel from '~/components/CMS/HeroCarousel/HeroCarousel.vue';
import HeroGallery from '~/components/CMS/HeroGallery/HeroGallery.vue';
import HeroHeader from '~/components/CMS/HeroHeader/HeroHeader.vue';
import HeroTitle from '~/components/CMS/HeroTitle';
import ImageCardList from '~/components/CMS/ImageCardList/ImageCardList';
import ImageCarousel from '~/components/CMS/ImageCarousel/ImageCarousel.vue';
import ImageGrid from '~/components/CMS/ImageGrid/ImageGrid';
import InspirationGrid from '~/components/CMS/InspirationGrid/InspirationGrid';
import LatestVideos from '~/components/CMS/LatestVideos/LatestVideos.vue';
import MakesCarousel from '~/components/CMS/MakesCarousel/MakesCarousel.vue';
import PageTitle from '~/components/CMS/PageTitle/PageTitle.vue';
import ProductCarousel from '~/components/CMS/ProductCarousel/ProductCarousel';
import ProjectCarsListingHeader from '~/components/CMS/ProjectCarsListingHeader/ProjectCarsListingHeader';
import PromotionContent from '~/components/CMS/PromotionContent/PromotionContent.vue';
import QuoteBlockWithImage from '~/components/CMS/QuoteBlockWithImage/QuoteBlockWithImage';
import ResponsiveImage from '~/components/CMS/ResponsiveImage/ResponsiveImage.vue';
import RichTextDisplay from '~/components/CMS/RichTextDisplay/RichTextDisplay.vue';
import BannerCarousel from '~/components/CMS/SecondaryImageBanner/BannerCarousel/BannerCarousel.vue';
import BannerImage from '~/components/CMS/SecondaryImageBanner/BannerImage/BannerImage.vue';
import SecondaryImageBanner from '~/components/CMS/SecondaryImageBanner/SecondaryImageBanner.vue';
import SelectVehicle from '~/components/CMS/SelectVehicle/SelectVehicle.vue';
import SEOContent from '~/components/CMS/SEOContent/SEOContent.vue';
import ShopAllCategories from '~/components/CMS/ShopAllCategories/ShopAllCategories.vue';
import SocialMediaLinks from '~/components/CMS/SocialMediaLinks/SocialMediaLinks';
import StoryblokAccordion from '~/components/CMS/StoryblokAccordion/StoryblokAccordion';
import VehicleRelatedContent from '~/components/CMS/VehicleRelatedContent/VehicleRelatedContent';
import VideoGrid from '~/components/CMS/VideoGrid/VideoGrid.vue';
import YoutubeVideoInline from '~/components/CMS/YoutubeVideoInline/YoutubeVideoInline';
import ZipPay from '~/components/CMS/ZipPay/ZipPay.vue';
import Placeholder from '~/components/Placeholder.vue';
Vue.component('BannerCarousel', BannerCarousel);
Vue.component('BannerImage', BannerImage);
Vue.component('SecondaryImageBanner', SecondaryImageBanner);
Vue.component('BlogPostTitle', BlogPostTitle);
Vue.component('RichTextEditor', RichTextDisplay);
Vue.component('RichTextDisplay', RichTextDisplay);
Vue.component('FAQList', FAQList);
Vue.component('CustomerValueProposition', CustomerValueProposition);
Vue.component('CustomerValuePropositionItem', CustomerValuePropositionItem);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Carousel', HeroCarousel);
Vue.component('HeroGallery', HeroGallery);
Vue.component('HeroHeader', HeroHeader);
Vue.component('HeroTitle', HeroTitle);
Vue.component('ArticleGrid', ArticleGrid);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('AmbassadorProductGrid', AmbassadorProductGrid);
Vue.component('YoutubeVideoInline', YoutubeVideoInline);
Vue.component('VideoGrid', VideoGrid);
Vue.component('InspirationGrid', InspirationGrid);
Vue.component('FeaturedArticleBanner', FeaturedArticleBanner);
Vue.component('FeaturedContent', FeaturedContent);
Vue.component('FeaturedMediaCarousel', FeaturedMediaCarousel);
Vue.component('FeaturedMediaGrid', FeaturedMediaGrid);
Vue.component('FeaturedProductBanner', FeaturedProductBanner);
Vue.component('ImageCardList', ImageCardList);
Vue.component('ImageGrid', ImageGrid);
Vue.component('ProductCarousel', ProductCarousel);
Vue.component('BrandCarousel', BrandCarousel);
Vue.component('CategoryCarousel', CategoryCarousel);
Vue.component('FeaturedYoutubeVideo', FeaturedYoutubeVideo);
Vue.component('QuoteBlockWithImage', QuoteBlockWithImage);
Vue.component('PromotionContent', PromotionContent);
Vue.component('ContactForm', ContactForm);
Vue.component('SEOContent', SEOContent);
Vue.component('SelectVehicle', SelectVehicle);
Vue.component('CategoryHeader', CategoryHeader);
Vue.component('ShopAllCategories', ShopAllCategories);
Vue.component('PageTitle', PageTitle);
Vue.component('AnchorLinkedBlocks', AnchorLinkedBlocks);
Vue.component('StoryblokAccordion', StoryblokAccordion);
Vue.component('ZipPay', ZipPay);
Vue.component('VehicleRelatedContent', VehicleRelatedContent);
Vue.component('SocialMediaLinks', SocialMediaLinks);
Vue.component('ResponsiveImage', ResponsiveImage);
Vue.component('ImageCarousel', ImageCarousel);
Vue.component('LatestVideos', LatestVideos);
Vue.component('MakesCarousel', MakesCarousel);
Vue.component('ProjectCarsListingHeader', ProjectCarsListingHeader);

// might not be necessary unless whitelisted to existing content types
Vue.component('FooterMenu', Placeholder);
Vue.component('SocialMediaLink', Placeholder);
Vue.component('SimpleNavigationMenuItem', Placeholder);
