import { useUiHelpers } from '~/composables';
import { kebabCase } from '~/helpers/kebabCase';

export interface ICategory {
  content: {
    name: string;
    product_category: IProductCategoryDeep[] | IProductCategoryShallow;
    thumb_image: {
      filename: string;
      alt: string;
    };
  };
  full_slug: string;
}

interface IProductCategoryDeep {
  shopify_category: {
    items: {
      name: string;
    }[];
  };
}

interface IProductCategoryShallow {
  title: string;
}

export function getCategoryLink(category: ICategory): string {
  const { CATEGORY_ROUTE_PREFIX, SEARCH_CATEGORY_TENANT_PREFIX } = useUiHelpers();
  let categoryName = '';

  if (Array.isArray(category?.content?.product_category)) {
    categoryName = category?.content?.product_category[0]?.shopify_category?.items[0]?.name || '';
  } else {
    categoryName = category?.content?.product_category?.title || '';
  }

  return categoryName
    .split('>')
    .map((section: string) => kebabCase(section))
    .join('/')
    .replace(SEARCH_CATEGORY_TENANT_PREFIX, CATEGORY_ROUTE_PREFIX);
}
