<template>
  <ProductPage
    :product-gallery="productGallery"
    :product-fits-vehicle="productFitsVehicle"
    :is-universal-product="isUniversalProduct"
    :fitment-search-data="fitmentSearchData"
    :stock-qty="stockQty"
    :sku="sku"
    :product-tags="productTags"
    :is-special-order="isSpecialOrder"
    :cart-loading="cartLoading"
    :product-loading="productLoading"
    :product-description-html="productDescriptionHtml"
    :product-name="productTitle"
    :compare-to-price="compareToPrice"
    :price="price"
    :recommended-products="recommendedProducts"
    :options="options"
    :options-not-selected="optionsNotSelected"
    :has-options="hasOptions"
    :variant-by-selected-options="variantBySelectedOptions"
    :variant-image-index="variantImageIndex"
    :selected-options="selectedOptions"
    :product-handle="productHandle"
    :product-vendor="productVendor"
    :product-specifications="productSpecifications"
    :product-details-care="productDetailsCare"
    :product-attributes="productAttributes"
    :product-part-number="productPartNumber"
    :asku="asku"
    :breadcrumbs="productCategoriesBreadcrumbs"
    :handling-time="handlingTime"
    :availability="availability"
    :product-promotion-image="productPromotionImage"
    :product-promotion-handle="productPromotionHandle"
    :has-fitment="hasFitment"
    :variant-id="variantId"
    :product-weight="productWeight"
    @add-to-cart="addingToCart"
    @product-option-selected="productOptionSelected"
  />
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  provide,
  reactive,
  ref,
  useAsync,
  useContext,
  watch,
} from '@nuxtjs/composition-api';
import { searchGetters } from '@unified-commerce/gpc-vue-storefront-search-io';
import {
  cartGetters,
  productGetters,
  useCart,
  useProduct,
  useUser,
} from '@unified-commerce/gpc-vue-storefront-shopify';
import trim from 'lodash/trim';

import ProductPage from '~/components/Pages/ProductPage/ProductPage.vue';
import { useMyGarage, useRecentlyViewedProducts, useUiHelpers, useUiState } from '~/composables';
import useFitmentData from '~/composables/useFitmentData';
import useLocalStorageCart from '~/composables/useLocalStorageCart';
import useSbCategories from '~/composables/useSbCategories';
import useUiNotification from '~/composables/useUiNotification';
import { getProductFailedToAddCartNotification } from '~/composables/useUiNotification/commonNotifications';
import analytics from '~/helpers/analytics';
import {
  INJECTION_KEY_DEAL_EXPIRY,
  INJECTION_KEY_HAS_DEAL_EXPIRY,
} from '~/helpers/injectionKeys/productPage';
import { sanitiseProductId } from '~/helpers/shopify';

export const NOTIFICATION_KEY_PRODUCT_ADDED = 'product_added';

export default {
  name: 'ProductPageRoute',
  components: {
    ProductPage,
  },
  beforeRouteEnter(to, from, next) {
    const { resetVehiclesFromGarage } = useMyGarage();
    const { setPreviouslySelectedVehicle, userSelectedVehicle } = useUiState();
    const vehicleSlugRegex = new RegExp('/vehicles/.+$', 'ig');

    // if user comes from the vehicle specific page to PDP, disable the selected vehicle if applicable
    if (userSelectedVehicle.value && vehicleSlugRegex.test(from.path)) {
      setPreviouslySelectedVehicle(userSelectedVehicle.value);
      resetVehiclesFromGarage();
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    // While leaving the page, reset the garage state if previously selected vehicle is present
    const { addVehicle } = useMyGarage();
    const { previouslySelectedVehicle, setPreviouslySelectedVehicle } = useUiState();
    if (previouslySelectedVehicle.value) {
      addVehicle(previouslySelectedVehicle.value);
      setPreviouslySelectedVehicle(null);
    }
    next();
  },
  transition: 'fade',
  // eslint-disable-next-line max-lines-per-function
  setup(_props, context) {
    const { $config } = useContext();
    const { slug } = context.root.$route.params;
    const { userSelectedVehicle, setRecentlyAddedProduct } = useUiState();
    const { send: sendNotification } = useUiNotification();
    const { setCurrentRouteAsNotFound, setCurrentRouteAsInternalError } = useUiHelpers();
    const optionsNotSelected = ref([]);

    const { user, loading: userLoading } = useUser();

    const { getSbCategoryNameFromLink } = useSbCategories();

    const {
      loading: productLoading,
      products,
      search,
      error: productError,
    } = useProduct('products');
    const { products: recommendedProducts, search: searchRecommendedProducts } =
      useProduct('recommendedProducts');

    const { addItem, loading: cartLoading, error: cartError } = useCart();
    const { setCartLocalStorage } = useLocalStorageCart();

    const product = computed(
      () =>
        productGetters.getFiltered(products.value, {
          master: true,
          attributes: context?.root?.$route?.query,
        })[0],
    );

    const { pushRecentlyViewedProduct } = useRecentlyViewedProducts();
    onBeforeUnmount(() => {
      pushRecentlyViewedProduct(product.value);
    });

    const productTags = computed(() =>
      productGetters
        .getTags(product.value)
        .filter((tag) => tag.toLowerCase() !== 'sales_promo_active'),
    );

    const isSpecialOrder = computed(
      () =>
        productGetters.getProductMetafield(product.value, 'specialOrderRequired') &&
        productGetters.getSaleStatus(product.value) &&
        productGetters.getStock(product.value) <= 0,
    );

    const id = computed(() => (product.value ? productGetters.getId(product.value) : ''));

    const productTitle = computed(() =>
      product.value ? productGetters.getName(product.value) : '',
    );

    const productHandle = computed(() => productGetters.getSlug(product.value));

    const productDescriptionHtml = computed(() =>
      product.value ? productGetters.getDescription(product.value, true) : '',
    );

    const productFitsVehicle = computed(() => vehicleAskus.value?.askus?.includes(asku.value));

    const price = computed(() => productGetters.getProductPrice(product.value).price);

    const variantId = computed(() => {
      if (product.value) {
        if (hasVariantSelected.value) {
          return sanitiseProductId(variantBySelectedOptions?.value?.id);
        }

        return sanitiseProductId(product.value?.variants?.[0]?.id);
      }

      return '';
    });

    const compareToPrice = computed(
      () => productGetters.getProductPrice(product.value).compareToPrice,
    );

    const hasDealExpiry = computed(() =>
      product.value ? productGetters.hasDealExpiry(product.value) : false,
    );
    const dealExpiry = computed(() =>
      product.value ? productGetters.getDealExpiryDate(product.value) : '',
    );

    const hasOptions = computed(() =>
      product.value ? productGetters.hasOptions(product.value) : false,
    );
    const options = computed(() =>
      product.value ? productGetters.getOptions(product.value, true) : {},
    );

    const variantBySelectedOptions = computed(() =>
      product.value ? productGetters.getVariantBySelectedOptions(product.value) : {},
    );
    const hasVariantSelected = computed(() =>
      product.value ? productGetters.hasVariantSelected(product.value) : false,
    );

    const variantImageIndex = computed(() =>
      product.value
        ? productGetters.getVariantImageIndex(
            product.value,
            hasVariantSelected.value ? variantBySelectedOptions.value.image.originalSrc : '',
          )
        : 0,
    );

    const productGallery = computed(() => {
      if (product.value && product.value.images.length === 0) {
        product.value.images.push({
          originalSrc:
            'https://cdn.shopify.com/s/files/1/0407/1902/4288/files/placeholder_600x600.jpg?v=1625742127',
        });
      }

      return productGetters.getGallery(product.value).map((media) => ({
        mobile: { url: media.small },
        desktop: { url: media.normal },
        big: { url: media.big },
        alt: product.value?.name || '',
        video: media.video,
      }));
    });

    const stockQty = computed(() => {
      return product.value ? productGetters.getStock(product.value, { supportFreeSale: true }) : 0;
    });

    const sku = computed(() => productGetters.getSKU(product.value));

    const productAttributes = computed(() => {
      return {
        contentType: 'attribute-list',
        title: 'Product attributes',
        content: productGetters.getProductAttribute(product.value),
      };
    });

    const productDetailsCare = computed(() => {
      return {
        contentType: 'html',
        title: 'Details & Care',
        content: product.value
          ? productGetters.getProductMetafield(product.value, 'detail_care')
          : null,
      };
    });

    const productVendor = computed(() => productGetters.getVendorName(product.value));

    const handlingTime = computed(() => {
      return productGetters.getProductVariantMetafield(product.value, 'handlingTime') || '3';
    });

    const availability = computed(() => {
      return productGetters.getProductAvailability(product.value, { supportFreeSale: true });
    });

    const hasFitment = computed(
      () => productGetters.getProductVariantMetafield(product.value, 'hasFitment') === 'true',
    );

    const productSpecifications = computed(() => {
      return {
        contentType: 'attribute-list',
        title: 'Specifications',
        content: productGetters.getProductSpecification(product.value),
      };
    });

    const productPartNumber = computed(() =>
      productGetters.getProductVariantMetafield(product.value, 'partNumber'),
    );

    const productPromotionHandle = computed(() => {
      const handle = productGetters.getProductVariantMetafield(
        product.value,
        'productPromoCollection',
      );
      if (!handle || typeof handle !== 'string') {
        return null;
      }

      return handle.replace(
        `${useUiHelpers().SEARCH_CATEGORY_TENANT_PREFIX_HANDLE}promotions-`,
        '/promotions/',
      );
    });

    const productPromotionImage = computed(() => {
      return product.value?.productPromoImage?.reference?.image?.originalSrc;
    });

    const productWeight = computed(() => {
      return productGetters.getWeight(product.value);
    });

    // fitment data injections
    const { getProductsFitmentData, productsFitmentResult, vehicleAskus } = useFitmentData();
    const asku = computed(() => productGetters.getProductVariantMetafield(product.value, 'asku'));

    const isUniversalProduct = computed(() => {
      return (
        Array.isArray(productsFitmentResult?.value?.results) &&
        productsFitmentResult.value.results?.some((result) => result?.record?.universal === '1')
      );
    });

    const fitmentSearchData = computed(() => searchGetters.getItems(productsFitmentResult.value));

    // not using watch causes fitment data not to be injected reactively
    watch(asku, async () => {
      await getProductsFitmentData([asku.value]);
    });

    // end fitment data injection
    const productCategoriesBreadcrumbs = computed(() =>
      productGetters.getProductCategoryBreadcrumbs(product.value, 'Sparesbox', {
        text: 'All Parts',
        link: '/parts',
      }),
    );

    const productCategory = computed(() => {
      const productCategoryLink =
        productCategoriesBreadcrumbs?.value[productCategoriesBreadcrumbs.value.length - 1]?.link;

      return getSbCategoryNameFromLink(productCategoryLink);
    });

    const shouldSendViewItemEvent = computed(() => {
      return product.value && productCategory.value && !userLoading.value;
    });

    watch([productCategory, user], () => {
      if (shouldSendViewItemEvent.value) {
        analytics({
          event: 'view_item',
          product: product.value,
          discount: compareToPrice?.value,
          price: price?.value,
          category: productCategory?.value,
          customer_id: user?.value?.id,
        });
      }
    });

    useAsync(async () => {
      await search({ slug });

      const vehicleSelectedCookie = await useMyGarage().getVehicleSelectionFromCookie();

      if (!product.value) {
        setCurrentRouteAsNotFound({
          errorMessage: 'This product could not be found',
          data: [product, vehicleAskus, userSelectedVehicle, vehicleSelectedCookie],
        });
      } else if (productError.value.search) {
        setCurrentRouteAsInternalError({
          data: {
            error: productError.value.search,
            data: product.value,
          },
        });
      } else {
        await Promise.allSettled([
          searchRecommendedProducts({ productId: id.value, recommended: true }),
        ]);
      }
    }, slug);

    const addingToCart = async (quantity) => {
      if (hasOptions.value && selectedOptions.length !== options.value.length) {
        const selectedOptionsList = selectedOptions.map((selectedOption) => selectedOption.name);

        options.value.forEach((option) => {
          if (
            !selectedOptionsList.includes(option.name) &&
            !optionsNotSelected.value.includes(option.name)
          ) {
            optionsNotSelected.value.push(option.name);
          }
        });
        return;
      }

      await addItem({
        product: product.value,
        quantity,
      });

      analytics({
        event: 'add_to_cart',
        id: product?.value?.id,
        name: product?.value?.name,
        price: price?.value,
        vendor: product?.value?.vendor,
        category: productCategory?.value,
        customer_id: user?.value?.id,
      });

      setCartLocalStorage();

      if (cartError.value.addItem) {
        sendNotification(
          getProductFailedToAddCartNotification({
            name: product.value.name,
          }),
        );
      } else {
        setRecentlyAddedProduct({
          variantId: product.value.id,
          name: product.value.name,
          brand: product.value.vendor,
          price: product.value.price.original || product.value.price.current,
          specialPrice: product.value.price.original ? product.value.price.current : null,
          sku: sku.value,
          partNumber: productPartNumber.value,
          img: productGallery.value[0]?.mobile?.url || '',
        });
      }
    };

    const selectedOptions = reactive([]);

    const productOptionSelected = async (selected) => {
      optionsNotSelected.value = [];
      const index = selectedOptions.findIndex((o) => o.name === selected.name);

      if (index !== -1) {
        selectedOptions.splice(index, 1);
      }
      selectedOptions.push(selected);

      if (options.value.length === selectedOptions.length) {
        await search({ slug, selectedOptions });
      }
    };

    watch(userSelectedVehicle, async (userSelectedVehicle) => {
      if (userSelectedVehicle?.KtypNr) {
        await getProductsFitmentData([asku.value]);
      }
    });

    provide(
      INJECTION_KEY_DEAL_EXPIRY,
      computed(() => dealExpiry.value),
    );
    provide(
      INJECTION_KEY_HAS_DEAL_EXPIRY,
      computed(() => hasDealExpiry.value),
    );

    return {
      product,
      productFitsVehicle,
      isUniversalProduct,
      fitmentSearchData,
      productDescriptionHtml,
      sendNotification,
      productTags,
      isSpecialOrder,
      stockQty,
      productTitle,
      addItem,
      cartLoading,
      productLoading,
      productGallery,
      productHandle,
      productGetters,
      cartGetters,
      sku,
      handlingTime,
      price,
      compareToPrice,
      addingToCart,
      recommendedProducts,
      dealExpiry,
      hasDealExpiry,
      options,
      optionsNotSelected,
      hasOptions,
      productOptionSelected,
      variantBySelectedOptions,
      hasVariantSelected,
      variantImageIndex,
      selectedOptions,
      productVendor,
      productSpecifications,
      productAttributes,
      productPartNumber,
      asku,
      availability,
      productDetailsCare,
      productCategoriesBreadcrumbs,
      productPromotionImage,
      productPromotionHandle,
      variantId,
      hasFitment,
      productWeight,
      productCategory,
    };
  },
  head() {
    const { path } = this.$route;

    if (this.product) {
      const structuredData = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        '@id': `https://${this.$config.BASE_URL}${trim(path)}`,
        name: this.product.title,
        image: this.productGallery.map((image) => image.desktop.url),
        description: this.product.description,
        keywords: (this.product.tags || []).join(', '),
        sku: productGetters.getSKU(this.product),
        brand: {
          '@type': 'Brand',
          name: this.product.vendor || '',
        },
        offers: {
          '@type': 'Offer',
          url: `https://${this.$config.BASE_URL}${trim(path)}`,
          priceCurrency: 'AUD',
          price: this.price ? this.price.toString() : '',
          itemCondition: 'https://schema.org/NewCondition',
          availability:
            this.stockQty > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
        },
      };

      if (productGetters.isDeal(this.product)) {
        structuredData.offers.sale_price = this.price ? this.price.toString() : '';
        structuredData.offers.price = this.compareToPrice ? this.compareToPrice.toString() : '';
        structuredData.offers.priceValidUntil = productGetters.getDealExpiryDate(this.product);
      }

      return {
        title: this.product.title,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.product.description,
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: `https://${this.$config.BASE_URL}${trim(path)}`,
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: this.product.title,
          },
          {
            hid: 'og:type',
            property: 'og:type',
            content: 'product',
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: this.product.description,
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: this.productGallery[0].desktop.url,
          },
          {
            hid: 'og:image:secure_url',
            property: 'og:image:secure_url',
            content: this.productGallery[0].desktop.url,
          },
          {
            hid: 'og:price:amount',
            property: 'og:price:amount',
            content: this.price ? this.price.toString() : '',
          },
          {
            hid: 'og:price:currency',
            property: 'og:price:currency',
            content: 'AUD',
          },
          {
            hid: 'twitter:title',
            name: 'twitter:title',
            content: this.product.title,
          },
          {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: this.product.description,
          },
          {
            hid: 'twitter:image',
            name: 'twitter:image',
            content: this.productGallery[0].desktop.url
              ? this.productGallery[0].desktop.url
              : '~/static/icons/sparesbox-app-icon-512x512.png',
          },
          {
            hid: 'twitter:card',
            name: 'twitter:card',
            content: this.productGallery[0].desktop.url ? 'summary_large_image' : 'summary',
          },
        ],
        script: [
          {
            type: 'application/ld+json',
            json: structuredData,
          },
        ],
      };
    }

    return {};
  },
};
</script>
