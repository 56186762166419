const isAbsoluteUrl = (link: string) => link.indexOf('://') > 0 || link.indexOf('//') === 0;

const removeTrailingSlash = (link: string) => link.replace(/\/+$/, '');

export const getUrlLink = (link: string) => {
  if (isAbsoluteUrl(link)) {
    return removeTrailingSlash(link);
  }

  const cleanedLink = removeTrailingSlash(link);

  return cleanedLink.startsWith('/') ? cleanedLink : `/${cleanedLink}`;
};
