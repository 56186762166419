var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UCCardSection',{staticClass:"image-card-list",attrs:{"title":_vm.title,"description":_vm.description,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: '285px',
    cardFlexGrow: 1,
    showSteppers: false,
  },"tablet":{
    cardMode: 'wrap',
    cardSpacing: 0,
    cardFlexBasis: 'calc(100% / 3)',
    groupStyle: {
      borderRadius: '4px',
      overflow: 'hidden',
    },
  },"desktop":{
    cardFlexBasis: '25%',
  }}},_vm._l((_vm.cards),function(card,index){return _c('ImageCard',{key:index,attrs:{"card":card}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }