import { Ref } from '@nuxtjs/composition-api';
import { useContent } from '@unified-commerce/gpc-vue-storefront-storyblok';

export interface EmailSubscriptionContent {
  component?: string;
  email_placeholder_text?: string;
  emal_placeholder_text?: string;
  headers?: Record<string, unknown>;
  heading_text?: string;
  sub_heading_text?: string;
  submit_button_text?: string;
  success_heading_text?: string;
  success_sub_heading_text?: string;
  _meta?: Record<string, unknown>;
  _uid?: string;
}

export interface UseEmailSubscriptionReturnProps {
  search: (params: unknown) => Promise<void>;
  content: Ref<EmailSubscriptionContent>;
  loading: Ref<boolean>;
}

const useEmailSubscription = (): UseEmailSubscriptionReturnProps => {
  const { search, content, loading } = useContent('emailSubscriptionContent');

  return {
    search,
    content,
    loading,
  };
};

export default useEmailSubscription;
