import { ISearchParams, IVehicleFilters } from '@unified-commerce/gpc-vue-storefront-search-io';
import { ComposableFunctionArgs } from '@vue-storefront/core';

import getVehicleParams from '~/helpers/vehicle/getVehicleParams';

export type SearchType = 'Make' | 'Model' | 'Year' | 'Series' | 'BodyType' | 'EngineCode' | 'All';

type SearchFunctions = {
  [key in SearchType]: (params: ComposableFunctionArgs<ISearchParams>) => Promise<void>;
};

type VehicleSelectionDataParams = {
  selectionType: SearchType;
  filters?: IVehicleFilters;
};

export const getVehicleSelectionsData =
  (searchFunctions: SearchFunctions) =>
  ({ selectionType, filters }: VehicleSelectionDataParams) => {
    return searchFunctions[selectionType]({
      ...getVehicleParams(),
      vehicleFilters: filters,
    });
  };
