var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UCCardSection',{staticClass:"ambassador-product-grid",attrs:{"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: '236px',
    showDescription: false,
    showSteppers: false,
  },"tablet":{
    cardMode: 'wrap',
    cardFlexBasis: 'calc(100% / 3)',
  },"desktop":{
    cardFlexBasis: '25%',
    groupStyle: {
      padding: '0 168px',
    },
  }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header-inner"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),(_vm.linkUrl && _vm.link_text)?_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.linkUrl}},[_vm._v("\n        "+_vm._s(_vm.link_text)+"\n        "),_c('i',{staticClass:"fa-solid fa-chevron-right link-icon"})]):_vm._e()],1)]},proxy:true},{key:"default",fn:function(){return _vm._l((_vm.productsTyped),function(product,i){return _c('UCProductCard',{key:i,attrs:{"tags":_vm.productGetters.getTags(product),"savings-tag":_vm.productGetters.getSavingsLabel(product),"image-src":_vm.productGetters.getCoverImage(product),"fitment":'Engine Bay',"fits-vehicle":true,"brand":_vm.productGetters.getVendorName(product),"brand-link":_vm.getBrandLinkByVendor(_vm.brandsAsArray, _vm.productGetters.getProductVendor(product)),"title":_vm.productGetters.getName(product),"sku":_vm.productGetters.getSKU(product),"price":_vm.productGetters.getPrice(product),"compare-to-price":_vm.productGetters.getCompareToPrice(product),"rating":3.5,"review-count":219,"in-stock":Boolean(_vm.productGetters.getStock(product)),"link":("/products/" + (_vm.productGetters.getSlug(product))),"mode":"compact","availability":_vm.productGetters.getProductAvailability(product),"variant-id":_vm.productGetters.getVariantId(product),"part-number":_vm.productGetters.getProductVariantMetafield(product, 'partNumber')}})})},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }