import { Ref } from '@nuxtjs/composition-api';
import { useContent } from '@unified-commerce/gpc-vue-storefront-storyblok';

export interface LinkHrefResponse {
  cached_url?: string;
  fieldtype?: string;
  id?: string;
  linktype?: string;
  url?: string;
}

export interface ContentResponse {
  component?: string;
  link_href?: LinkHrefResponse;
  link_text?: string;
  _uid?: string;
}

export interface FooterContentResponse {
  about?: ContentResponse[];
  component?: string;
  content?: ContentResponse[];
  copyright?: string;
  headers?: Record<string, unknown>;
  helpSupport?: ContentResponse[];
  shop?: ContentResponse[];
  socialMedia?: {
    component?: string;
    icon?: Record<string, unknown>;
    social_media_link?: string;
    social_media_name?: string;
    _uid?: string;
  }[];
  _meta?: Record<string, unknown>;
  _uid?: string;
}

export interface UseFooterContentReturnProps {
  search: (params: unknown) => Promise<void>;
  content: Ref<FooterContentResponse>;
  loading: Ref<boolean>;
}

const useFooterContent = (): UseFooterContentReturnProps => {
  const { search, content, loading } = useContent('footerMenuContent');

  return {
    search,
    content,
    loading,
  };
};

export default useFooterContent;
