import { onMounted, ref } from '@nuxtjs/composition-api';
import { Product } from '@unified-commerce/gpc-vue-storefront-shopify';

const LS_KEY = 'recentlyViewedProducts';
const MAX_RECENTLY_VIEWED_PRODUCTS = 10;
const recentlyViewedProducts = ref<Product[]>([]);

export default function useRecentlyViewedProducts() {
  onMounted(() => {
    const prods = window.localStorage.getItem(LS_KEY);
    if (prods) {
      try {
        recentlyViewedProducts.value = JSON.parse(prods).filter(Boolean);
      } catch (e) {
        console.error('Error Parsing Recently Viewed Products', e);
      }
    }
  });

  const pushRecentlyViewedProduct = (product: Product) => {
    if (!product) {
      recentlyViewedProducts.value = [];
    }

    const products = recentlyViewedProducts.value.filter((p) => p.id !== product.id);

    if (products.length >= MAX_RECENTLY_VIEWED_PRODUCTS) {
      products.pop();
    }

    products.unshift(product);

    recentlyViewedProducts.value = products.filter(Boolean); // prevent saving of null/undefined/falsy values;

    if (process.client) {
      try {
        window.localStorage.setItem(LS_KEY, JSON.stringify(products));
      } catch (e) {
        console.error('Error Setting Recently Viewed Products', e);
      }
    }
  };

  return { recentlyViewedProducts, pushRecentlyViewedProduct };
}
