import { UiNotification } from '.';

export const PRODUCT_FAILED_TO_ADD_TO_CART_NOTIFICATION_KEY = 'product_failed_to_add_to_cart';
export const getProductFailedToAddCartNotification = ({
  name,
}: {
  name: string;
}): UiNotification => ({
  key: PRODUCT_FAILED_TO_ADD_TO_CART_NOTIFICATION_KEY,
  type: 'danger',
  message: `${name} could not be added to your cart.`,
  icon: '',
});
