var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UCCardSection',{staticClass:"image-carousel",attrs:{"title":_vm.title,"description":_vm.description,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: '50%',
    showSteppers: false,
  },"tablet":{
    cardFlexBasis: '25%',
    showSteppers: true,
  },"desktop":{
    cardFlexBasis: '20%',
  }}},_vm._l((_vm.images),function(image,i){return _c('StoryblokImage',{key:i,staticClass:"image",attrs:{"src":_vm.getImgSrc(image)}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }