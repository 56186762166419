import { ILocation } from '@unified-commerce/gpc-vue-storefront-google-places';
import { useQuote, UseQuoteResult } from '@unified-commerce/gpc-vue-storefront-shippit';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days2';

const useEstimateDelivery = (id: string) => {
  const {
    getQuote: getDeliveryEstimateQuote,
    clearQuote: clearDeliveryEstimateQuote,
    loading: deliveryEstimateQuoteLoading,
    error: deliveryEstimateQuoteError,
    result: deliveryEstimateQuoteResult,
  } = useQuote(id);

  const estimateDeliveryQuote = async (location: ILocation | null, productWeight: number) => {
    if (location) {
      await getDeliveryEstimateQuote({
        postcode: location.postcode,
        state: location.state,
        suburb: location.suburb,
        products: [
          {
            qty: 1,
            weight: productWeight,
          },
        ],
      });
    }
  };

  const nationalHolidays = [
    '25-12', // Christmas
    '26-12', // Boxing Day
    '01-01', // New Year's Day
    '29-03', // Good Friday
    '25-04', // Anzac Day
  ];

  const getDeliveryEstimateText = (
    estimate: UseQuoteResult,
    handlingTime: string,
    holidays: string[] = nationalHolidays,
    fixedDate?: dayjs.Dayjs,
  ): string => {
    dayjs.extend(dayjsBusinessDays);

    const currentDate = fixedDate || dayjs();

    if (!estimate) return '';

    const totalDays = parseInt(estimate.estimatedTransitTime, 10) + parseInt(handlingTime, 10);
    let estimatedDate = currentDate.businessDaysAdd(totalDays);

    // Loop to adjust for holidays
    while (holidays.includes(estimatedDate.format('DD-MM'))) {
      estimatedDate = estimatedDate.businessDaysAdd(1);
    }

    // Return the formatted final delivery date
    return estimatedDate.format('dddd, MMMM D, YYYY');
  };

  return {
    clearDeliveryEstimateQuote,
    estimateDeliveryQuote,
    deliveryEstimateQuoteLoading,
    deliveryEstimateQuoteError,
    deliveryEstimateQuoteResult,
    getDeliveryEstimateText,
  };
};

export default useEstimateDelivery;
