var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.computedHeroes.length)?_c('BaseHero',{staticClass:"hero",attrs:{"slider-options":_vm.sliderOptions,"arrow-icon":"chevron"}},_vm._l((_vm.computedHeroes),function(hero,i){return _c('BaseHeroItem',{key:i,scopedSlots:_vm._u([{key:"withImgTag",fn:function(){return [_c('div',{staticClass:"hero-item__background"},[_c('StoryblokImage',{staticClass:"hero-item__background-img",attrs:{"loading":"eager","preload":true,"src":_vm.getImage(hero) || '',"alt":_vm.getImageAlt(hero) || '',"sizes":{
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true,
            '2xl': true,
            '3xl': true,
            '4xl': true,
          }}})],1)]},proxy:true},{key:"call-to-action",fn:function(){return [_c('nuxt-link',{staticClass:"hero-item__overlay-link",attrs:{"aria-label":"hero item","to":hero.ctaLink}})]},proxy:true}],null,true)})}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }