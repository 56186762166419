import { onMounted, ref } from '@nuxtjs/composition-api';
import { ILocation } from '@unified-commerce/gpc-vue-storefront-google-places';

import { clear, load, save } from '~/helpers/localStorage';

const SUBURB_LOCAL_STORAGE_KEY = 'suburb';

function locationHasPostcode(location: ILocation) {
  if (location.postcode) {
    return true;
  } else {
    console.warn('Location does not have a postcode');
    return false;
  }
}

/**
 * handles postcodes and suburb addresses.
 */
const useSuburb = () => {
  const suburb = ref<ILocation | null>(null);

  onMounted(() => {
    const localStorageValue = load<ILocation | null>(SUBURB_LOCAL_STORAGE_KEY);
    if (localStorageValue) {
      if (locationHasPostcode(localStorageValue)) {
        suburb.value = localStorageValue;
      } else {
        clear(SUBURB_LOCAL_STORAGE_KEY);
      }
    }
  });

  const setSuburb = (suburbDetails: ILocation) => {
    suburb.value = suburbDetails;
    save(SUBURB_LOCAL_STORAGE_KEY, suburbDetails);
  };

  return {
    suburb,
    setSuburb,
  };
};

export default useSuburb;
