var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UCCardSection',{staticClass:"product-carousel",attrs:{"title":_vm.title,"description":_vm.description,"link":_vm.linkUrl,"link-text":_vm.link_text,"separator":_vm.separator,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: '236px',
    showDescription: false,
    showSteppers: false,
  },"tablet":{
    cardFlexBasis: 'calc(100% / 3)',
    showDescription: true,
    showSteppers: true,
  },"desktop":{
    cardFlexBasis: '25%',
  }}},_vm._l((_vm.productsTyped),function(product,i){return _c('UCProductCard',{key:i,attrs:{"tags":_vm.productGetters.getTags(product),"savings-tag":_vm.productGetters.getSavingsLabel(product),"image-src":_vm.productGetters.getCoverImage(product, 'medium'),"brand":_vm.productGetters.getVendorName(product),"brand-link":_vm.getBrandLinkByVendor(_vm.brandsAsArray, _vm.productGetters.getProductVendor(product)),"title":_vm.productGetters.getName(product),"sku":_vm.productGetters.getSKU(product),"price":_vm.productGetters.getPrice(product),"compare-to-price":_vm.productGetters.getCompareToPrice(product),"rating":3.5,"review-count":219,"in-stock":Boolean(_vm.productGetters.getStock(product)),"link":("/products/" + (_vm.productGetters.getSlug(product))),"availability":_vm.productGetters.getProductAvailability(product),"variant-id":_vm.productGetters.getVariantId(product),"part-number":_vm.productGetters.getProductVariantMetafield(product, 'partNumber')}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }