import { Collection, ISearchParams } from '@unified-commerce/gpc-vue-storefront-search-io';

const getFitmentSearchParams = (askus: string | string[], ktypNr?: string): ISearchParams => {
  let filter = '';

  if (Array.isArray(askus)) {
    const escapedASKUs = askus.map((string) => `"${string}"`);

    filter = `asku IN (${escapedASKUs.join(',')})`;
  } else {
    filter = `asku IN ("${askus}")`;
  }

  if (ktypNr) {
    filter += ` AND ktypnr IN (${ktypNr})`;
  }

  return {
    collection: Collection.FITMENT,
    pipeline: {},
    filter,
  };
};

export default getFitmentSearchParams;
