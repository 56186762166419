import { computed, Ref, useContext } from '@nuxtjs/composition-api';
import {
  ApiError,
  getStoryBlokVersion,
  useAllContent,
} from '@unified-commerce/gpc-vue-storefront-storyblok';

import { IBrand } from '../useBrands';

export interface UseBrandCategoriesProps {
  id: string;
}

export interface UseBrandCategoriesReturnProps {
  search: () => Promise<void>;
  error: Ref<ApiError | null>;
  brandCategories: Ref<any[]>;
  iBrandCategories: Ref<{ [handle: string]: IBrand }>;
  getLogoFileName: (handle: string) => string | undefined;
  getLogoAlt: (handle: string) => string;
}

const useBrandCategories = (
  { id }: UseBrandCategoriesProps = { id: 'brand-categories' },
): UseBrandCategoriesReturnProps => {
  const { $config } = useContext();
  const {
    search: searchBrandCategories,
    content: readOnlyBrandCategories,
    error,
  } = useAllContent(id);

  const brandCategoriesError = computed(() => error.value.search as ApiError);

  const search = async () => {
    await searchBrandCategories({
      version: getStoryBlokVersion($config.ENV),
      filterQuery: {
        component: {
          in: 'Brand',
        },
      },
      sortBy: 'name:asc',
    });
  };

  const brandCategories = computed(() => [...(readOnlyBrandCategories.value || [])]);

  /**
   * @deprecated
   * This is to temporarily replace the old brand categories object type which was previously stored in the store,
   * but we should be using "brandCategories" and refactor to phase this variable out.
   */
  const iBrandCategories = computed(() =>
    brandCategories.value.reduce((acc, category) => {
      const handle =
        category?.content?.product_category?.[0].shopify_category?.items?.[0]?.handle || '';

      if (!handle) {
        return acc;
      }

      return {
        ...acc,
        [handle]: {
          logo: {
            alt: category.content?.logo?.alt || '',
            filename: category.content?.logo?.filename || '',
          },
          name: category.content?.name || '',
          hierarchy:
            category.content?.product_category?.[0].shopify_category?.items?.[0]?.name || '',
          slug: category.slug || '',
        },
      };
    }, {} as { [handle: string]: IBrand }),
  );

  const getLogoFileName = (handle: string) =>
    brandCategories.value.find((brandCategory) =>
      brandCategory.content?.product_category?.find((productCategory: any) =>
        productCategory.shopify_category?.items?.find((item: any) => {
          return item.handle === handle;
        }),
      ),
    )?.content?.logo?.filename;

  const getLogoAlt = (handle: string) =>
    brandCategories.value.find((brandCategory) =>
      brandCategory.content?.product_category?.find((productCategory: any) =>
        productCategory.shopify_category?.items?.find((item: any) => {
          return item.handle === handle;
        }),
      ),
    )?.content?.logo?.alt || '';

  return {
    search,
    error: brandCategoriesError,
    brandCategories,
    getLogoFileName,
    getLogoAlt,
    iBrandCategories,
  };
};

export default useBrandCategories;
