export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    if (to.path !== '/' && to.path.endsWith('/')) {
      const newPath = to.path.replace(/\/+$/, '');
      const queryString = to.fullPath.slice(to.path.length);
      next(newPath + queryString);
    } else {
      next();
    }
  });
};
