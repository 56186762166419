import { render, staticRenderFns } from "./NavigationExpandedMenu.vue?vue&type=template&id=024c2f74&scoped=true&"
import script from "./NavigationExpandedMenu.vue?vue&type=script&lang=ts&"
export * from "./NavigationExpandedMenu.vue?vue&type=script&lang=ts&"
import style0 from "./NavigationExpandedMenu.vue?vue&type=style&index=0&id=024c2f74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024c2f74",
  null
  
)

export default component.exports