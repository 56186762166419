var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UCCardSection',{staticClass:"brand-carousel",attrs:{"title":_vm.title,"description":_vm.description,"link":_vm.linkUrl,"link-text":_vm.link_text,"separator":_vm.separator,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: '230px',
    showDescription: false,
    showSteppers: false,
  },"tablet":{
    cardFlexBasis: '25%',
    showDescription: true,
    showSteppers: true,
  },"desktop":{
    cardFlexBasis: '20%',
  }}},_vm._l((_vm.brands),function(brand,i){return _c('BrandCard',{key:i,attrs:{"image-src":brand.content.logo.filename,"image-alt":brand.content.logo.alt,"link":brand.full_slug}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }