function isBrandCategory(fullBrandHierarchy: string): boolean {
  /**
   * Brand category hierarchy so currently represented as follows
   * "Sparesbox Categories>Brand Categories>{{TOP LEVEL BRAND}}>{{CHILD BRAND CATEGORY}}"
   * e.g. "Sparesbox Categories>Brand Categories>CASTROL>Castrol Engine Oil"
   */
  if (typeof fullBrandHierarchy === 'string') {
    return fullBrandHierarchy.split('>').length > 3;
  }

  return false;
}

export default isBrandCategory;
