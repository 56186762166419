var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UCCardSection',{staticClass:"video-grid",attrs:{"title":_vm.title,"description":_vm.description,"link":_vm.linkUrl,"link-text":_vm.link_text,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: '301px',
    showDescription: false,
    showSteppers: false,
  },"tablet":{
    cardMode: 'wrap',
    cardFlexBasis: 'calc(100% / 3)',
    cardFlexGrow: 1,
    showDescription: true,
  }}},_vm._l((_vm.video_pages),function(videoPage,i){return _c('UCVideoMediaCard',{key:i,attrs:{"video-src":_vm.getYoutubeVideoURL(videoPage),"title":videoPage.content.title,"description":videoPage.content.description,"author":videoPage.content.author.name,"link":videoPage.full_slug,"show-link-text":true}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }