var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UCCardSection',{staticClass:"featured-media-carousel",attrs:{"title":_vm.title,"description":_vm.description,"link":_vm.linkUrl,"link-text":_vm.link_text,"dark":_vm.dark,"separator":_vm.separator,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: '301px',
    showDescription: false,
    showSteppers: false,
  },"tablet":{
    cardFlexBasis: '50%',
    showDescription: true,
    showSteppers: true,
  },"desktop":{
    cardFlexBasis: 'calc(100% / 3)',
  }}},_vm._l((_vm.media_pages),function(item,i){return _c('Fragment',{key:i},[(item.content && item.content.component === 'VideoPage')?_c('UCVideoMediaCard',_vm._b({attrs:{"dark":_vm.dark}},'UCVideoMediaCard',_vm.mapToVideoMediaCard(item),false)):_c('UCImageMediaCard',_vm._b({attrs:{"dark":_vm.dark}},'UCImageMediaCard',_vm.mapToImageMediaCard(item),false))],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }