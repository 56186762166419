import { computed, ssrRef, useAsync, useContext, useRoute } from '@nuxtjs/composition-api';
import { useStoryblokResolver } from '@unified-commerce/gpc-vue-storefront-shopify';
import {
  getStoryBlokVersion,
  useContent,
  useStoryblokBridge,
} from '@unified-commerce/gpc-vue-storefront-storyblok';
import trim from 'lodash/trim';

import { useUiHelpers } from '~/composables';

const usePage = (name: string, relations: string[] = [], custom?: object) => {
  const loading = ssrRef(false, `${name}-loading`);

  const route = useRoute();
  const path = trim(route.value.path, '/');
  const { search, content, error } = useContent(path);
  const story = computed(() => content.value);
  const { $config } = useContext();
  const relationsString = relations.join(',');
  const { resolveShopifyData } = useStoryblokResolver();
  const { initStoryblokBridgeSSRData, pageData: data } = useStoryblokBridge(
    `${route.value.path}-data`,
    relations,
    resolveShopifyData,
  );
  const hasData = computed(() => Object.keys(data.value).length !== 0);

  const { setCurrentRouteAsNotFound, setCurrentRouteAsInternalError } = useUiHelpers();

  useAsync(async () => {
    if (!hasData.value) {
      loading.value = true;

      await search({
        url: path,
        version: getStoryBlokVersion($config.ENV),
        ...(relationsString ? { relations: relationsString } : {}),
        ...(custom ? { custom } : {}),
      });

      loading.value = false;

      if (error.value.search) {
        if (error.value.search?.statusCode === 404) {
          setCurrentRouteAsNotFound({
            data: { name: `${name} page`, detail: [content, story, path] },
          });
        } else {
          setCurrentRouteAsInternalError({
            data: error.value.search,
          });
        }
      } else {
        await initStoryblokBridgeSSRData(story);
      }
    }
  }, path);

  return {
    data,
    loading,
    error,
    story,
  };
};

export default usePage;
