var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UCCardSection',{staticClass:"featured-media-grid",attrs:{"title":_vm.title,"description":_vm.description,"link":_vm.linkUrl,"link-text":_vm.link_text,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: '301px',
    showDescription: false,
    showSteppers: false,
  },"tablet":{
    cardMode: 'wrap',
    cardFlexBasis: _vm.is_first_card_large ? ['100%', 'calc(100% / 3)'] : 'calc(100% / 3)',
    cardFlexGrow: 1,
    showDescription: true,
  }}},_vm._l((_vm.media_pages),function(item,i){return _c('Fragment',{key:i},[(_vm.is_first_card_large && i === 0)?_c('Fragment',[(item.content && item.content.component === 'VideoPage')?_c('UCMegaVideoMediaCard',_vm._b({},'UCMegaVideoMediaCard',_vm.mapToVideoMediaCard(item),false)):_c('UCMegaImageMediaCard',_vm._b({},'UCMegaImageMediaCard',_vm.mapToImageMediaCard(item),false))],1):_c('Fragment',[(item.content && item.content.component === 'VideoPage')?_c('UCVideoMediaCard',_vm._b({},'UCVideoMediaCard',_vm.mapToVideoMediaCard(item),false)):_c('UCImageMediaCard',_vm._b({},'UCImageMediaCard',_vm.mapToImageMediaCard(item),false))],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }