import { computed, Ref } from '@nuxtjs/composition-api';
import { Collection, useCategory } from '@unified-commerce/gpc-vue-storefront-shopify';

export interface UseShopifyCategoriesProps {
  id: string;
}

export interface UseShopifyCategoriesReturnProps {
  search: () => Promise<void>;
  categories: Ref<Collection[]>;
  loading: Ref<boolean>;
  error: Error | null;
}

const useShopifyCategories = (
  { id }: UseShopifyCategoriesProps = { id: 'shopify-category' },
): UseShopifyCategoriesReturnProps => {
  const { search, categories, loading, error } = useCategory(id);

  const handleSearch = async () => {
    if (categories.value.length === 0) {
      await search({});
    }
  };

  return {
    search: handleSearch,
    categories: computed(() => [...categories.value]),
    loading,
    error: error.value.search,
  };
};

export default useShopifyCategories;
