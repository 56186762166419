import { useUiHelpers } from '~/composables';

export interface IBrandContent {
  _meta?: {
    slug?: string;
  };
  content?: {
    logo?: {
      filename: string;
      alt: string;
    };
    name?: string;
    description?: {
      content?: unknown[];
    };
    product_category?: {
      shopify_category?: {
        items?: {
          name?: string;
        }[];
      };
    }[];
  };
}

export interface IBrand {
  hierarchy: string;
  logo: {
    alt: string;
    filename: string;
  };
  name: string;
  slug: string;
}

const useBrands = () => {
  const { SEARCH_BRAND_PREFIX_TITLE, SEARCH_CATEGORY_PATH_SEPERATOR } = useUiHelpers();

  const SPLIT_BY_NUMBER = 20;

  const splitBrands = (brands: IBrand[]) => {
    return brands.reduce((acc, brand, index) => {
      const chunkIndex = Math.floor(index / SPLIT_BY_NUMBER);

      if (!acc[chunkIndex]) {
        acc[chunkIndex] = []; // start a new chunk
      }

      acc[chunkIndex].push(brand);

      return acc;
    }, [] as IBrand[][]);
  };

  const getBrandsByAlphabet = (brands: IBrand[], alphabet: string) => {
    return brands.filter((brand) => {
      if (alphabet === '#') {
        return /^\d/.test(brand.name);
      }
      return brand.name.startsWith(alphabet);
    });
  };

  const getTopLevelBrands = (brands: IBrand[]) => {
    return brands.filter((brand) => {
      return brand.hierarchy.split('>').length <= 3;
    });
  };

  const getBrandsInChunks = (brands: IBrand[], alphabet = '') => {
    if (!alphabet) {
      return splitBrands(brands);
    }
    return splitBrands(getBrandsByAlphabet(brands, alphabet));
  };

  const getBrandLinkByVendor = (brands: IBrand[], vendor?: string) => {
    if (vendor) {
      const brandByVendor = brands.find((brand) => {
        return brand.hierarchy.split('>').pop() === vendor;
      });
      if (brandByVendor) return `/brands/${brandByVendor.slug}`;
    }
    return '';
  };

  const getBrandLogoByVendor = (brands: IBrand[], vendor?: string) => {
    if (vendor) {
      const brandByVendor = brands.find((brand) => {
        return brand.hierarchy.split('>').pop() === vendor;
      });
      if (brandByVendor) return brandByVendor?.logo;
    }
    return null;
  };

  const getBrandFromSBArray = (brands: IBrandContent[], vendor: string) => {
    return brands.find(
      (entry) =>
        entry?.content?.product_category?.[0].shopify_category?.items?.[0]?.name ===
        `${SEARCH_BRAND_PREFIX_TITLE}${SEARCH_CATEGORY_PATH_SEPERATOR}${vendor}`,
    );
  };

  const getBrandLinkFromSB = (brand: IBrandContent) => {
    return `/brands/${brand?._meta?.slug || ''}`;
  };

  return {
    getTopLevelBrands,
    getBrandsInChunks,
    getBrandFromSBArray,
    getBrandLinkFromSB,
    getBrandLinkByVendor,
    getBrandLogoByVendor,
  };
};

export default useBrands;
