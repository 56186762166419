import { render, staticRenderFns } from "./SearchResultsBrands.vue?vue&type=template&id=08c9baaf&scoped=true&"
import script from "./SearchResultsBrands.vue?vue&type=script&lang=ts&"
export * from "./SearchResultsBrands.vue?vue&type=script&lang=ts&"
import style0 from "./SearchResultsBrands.vue?vue&type=style&index=0&id=08c9baaf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c9baaf",
  null
  
)

export default component.exports