import { computed, onMounted, onUnmounted, Ref, ref } from '@nuxtjs/composition-api';

export interface IConfig {
  cardMode: 'wrap' | 'scroll';
  cardSpacing: string | number;
  cardFlexBasis: string | string[];
  cardFlexGrow: number;
  rootStyle: object;
  headerStyle: object;
  loadingStyle: object;
  groupStyle: object;
  showDescription: boolean;
  showSteppers: boolean;
  noPadding: boolean;
}

export interface IBreakpoint {
  name: 'desktop' | 'tablet' | 'mobile';
  value: number;
}

const BREAKPOINTS: IBreakpoint[] = [
  { name: 'desktop', value: 1200 },
  { name: 'tablet', value: 768 },
  { name: 'mobile', value: 0 },
];

const defaultBreakpoint = BREAKPOINTS[2];

export function useBreakpoint(): { breakpoint: Ref<IBreakpoint> } {
  const breakpoint = ref(defaultBreakpoint);

  function resizeHandler() {
    breakpoint.value =
      BREAKPOINTS.find(
        (_breakpoint) => window.matchMedia(`(min-width: ${_breakpoint.value}px)`).matches,
      ) || defaultBreakpoint;
  }

  onMounted(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
  });

  onUnmounted(() => window.removeEventListener('resize', resizeHandler));

  return { breakpoint };
}

export interface IBreakpointProps {
  mobile: object;
  tablet: object;
  desktop: object;
}

export function useBreakpointConfig(
  props: IBreakpointProps,
  defaultConfig: IConfig,
): { config: Ref<IConfig> } {
  const { breakpoint } = useBreakpoint();

  const config = computed(
    () =>
      ({
        mobile: { ...defaultConfig, ...props.mobile },
        tablet: { ...defaultConfig, ...props.mobile, ...props.tablet },
        desktop: {
          ...defaultConfig,
          ...props.mobile,
          ...props.tablet,
          ...props.desktop,
        },
      }[breakpoint.value.name]),
  );

  return { config };
}
