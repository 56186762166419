import { useCart } from '@unified-commerce/gpc-vue-storefront-shopify';

import { clear, save } from '~/helpers/localStorage';

export const CART_LOCAL_STORAGE_KEY = 'cart';

const useLocalStorageCart = () => {
  const { cart } = useCart();
  const setCartLocalStorage = () => {
    save(CART_LOCAL_STORAGE_KEY, cart.value);
  };

  const clearCartLocalStorage = () => {
    clear(CART_LOCAL_STORAGE_KEY);
  };

  return {
    setCartLocalStorage,
    clearCartLocalStorage,
  };
};

export default useLocalStorageCart;
