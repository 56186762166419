var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UCCardSection',{staticClass:"article-grid",attrs:{"title":_vm.title,"description":_vm.description,"link":_vm.linkUrl,"link-text":_vm.link_text,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: '301px',
    showDescription: false,
    showSteppers: false,
  },"tablet":{
    cardMode: 'wrap',
    cardFlexBasis: 'calc(100% / 3)',
    cardFlexGrow: 1,
    showDescription: true,
  }}},_vm._l((_vm.article_pages),function(articlePage,i){return _c('UCImageMediaCard',{key:i,attrs:{"image-src":articlePage.content.banner_image.filename,"image-alt":articlePage.content.banner_image.alt,"tag":articlePage.content.media_category.name,"title":articlePage.content.title,"description":articlePage.content.description,"author":articlePage.content.author.name,"link":articlePage.full_slug,"show-link-text":true}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }