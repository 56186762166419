var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UCCardSection',{staticClass:"inspiration-grid",attrs:{"title":_vm.title,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: 'calc(2 * 100% / 3)',
    cardFlexGrow: 1,
  },"tablet":{
    cardMode: 'wrap',
    cardFlexBasis: '50%',
    headerStyle: {
      textAlign: 'center',
    },
  },"desktop":{
    cardFlexBasis: 'calc(100% / 3)',
    groupStyle: {
      padding: '0 118px',
    },
  }},scopedSlots:_vm._u([{key:"default",fn:function(){return _vm._l((_vm.items),function(item){return _c('div',{key:item._uid,staticClass:"card"},[(item.link.cached_url)?_c('BaseLink',{staticClass:"card-inner",attrs:{"link":_vm.getUrlLink(item.link.cached_url),"aria-label":_vm.getAriaLabel(item)}},[_c('StoryblokImage',{staticClass:"image",attrs:{"src":item.image.url.filename,"alt":item.image.url.alt}})],1):_c('div',{staticClass:"card-inner"},[_c('StoryblokImage',{staticClass:"image",attrs:{"src":item.image.url.filename,"alt":item.image.url.alt}})],1)],1)})},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }