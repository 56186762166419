export interface NuxtImgConfig {
  src: string;
  width?: number;
  height?: number;
  fitIn?: boolean;
  sizes?: {
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
    '2xl': boolean;
    '3xl': boolean;
    '4xl': boolean;
  };
  srcsets?: string;
}

const isSvgFile = (filename: string) => {
  const parts = filename.split('.');
  const extension = parts[parts.length - 1].toLowerCase();

  return extension === 'svg';
};

const getNuxtImgSizes = (sizes: NuxtImgConfig['sizes']) => {
  const nuxtImgSizes = [];

  if (sizes?.xs) {
    nuxtImgSizes.push('xs:320');
  }

  if (sizes?.sm) {
    nuxtImgSizes.push('sm:640');
  }

  if (sizes?.md) {
    nuxtImgSizes.push('md:768');
  }

  if (sizes?.lg) {
    nuxtImgSizes.push('lg:1024');
  }

  if (sizes?.xl) {
    nuxtImgSizes.push('xl:1280');
  }

  if (sizes?.['2xl']) {
    nuxtImgSizes.push('2xl:1536');
  }

  if (sizes?.['3xl']) {
    nuxtImgSizes.push('3xl:1920');
  }

  if (sizes?.['4xl']) {
    nuxtImgSizes.push('4xl:2560');
  }

  return nuxtImgSizes.join(' ');
};

const getNuxtImgConfig = ({ src, width, height, fitIn, sizes, srcsets }: NuxtImgConfig) => {
  // Storyblok image service does not support svg files, default to using default nuxt provider which is ipx.
  if (isSvgFile(src)) {
    return {};
  }

  const nuxtImgConfig: Record<string, unknown> = { provider: 'storyblok' };

  if (width) {
    nuxtImgConfig.width = width;
  }

  if (height) {
    nuxtImgConfig.height = height;
  }

  if (fitIn) {
    nuxtImgConfig.fit = 'in';
    nuxtImgConfig.modifiers = { filters: { fill: 'transparent' } };
  }

  if (sizes) {
    nuxtImgConfig.sizes = getNuxtImgSizes(sizes);
  }

  if (srcsets) {
    nuxtImgConfig.srcsets = srcsets;
  }

  return nuxtImgConfig;
};

export default getNuxtImgConfig;
