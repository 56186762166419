import { computed, Ref } from '@nuxtjs/composition-api';

import { extractRichTextItems } from '~/helpers/richText';
import { buildURL } from '~/helpers/videoEmbeddings/youtube';

const useDataFields = (data: Ref<any>, isDateLong = false) => {
  const bannerImageSrc = computed(() => data.value?.banner_image?.filename || '');
  const bannerImageAlt = computed(() => data.value?.banner_image?.alt || '');

  const date = computed(() =>
    data.value.date
      ? new Intl.DateTimeFormat('en-AU', {
          day: isDateLong ? 'numeric' : '2-digit',
          month: isDateLong ? 'long' : 'short',
          year: 'numeric',
        }).format(new Date(data.value?.date.replace(' ', 'T')))
      : '',
  );

  const title = computed(() => data.value?.title || '');
  const body = computed(() => extractRichTextItems(data.value?.body) || []);

  const author = computed(() => data.value.author?.name || '');

  const youtubeVideoUrl = computed(
    () => buildURL(data.value.youtube_video?.content?.youtube_video_id) || '',
  );

  return {
    bannerImageSrc,
    bannerImageAlt,
    date,
    title,
    body,
    author,
    youtubeVideoUrl,
  };
};

export default useDataFields;
