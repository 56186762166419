import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4109d4a8 = () => interopDefault(import('../pages/Account.vue' /* webpackChunkName: "pages/Account" */))
const _2038e468 = () => interopDefault(import('../pages/Account/AccountDetails.vue' /* webpackChunkName: "pages/Account/AccountDetails" */))
const _c1b34942 = () => interopDefault(import('../pages/Account/Addresses.vue' /* webpackChunkName: "pages/Account/Addresses" */))
const _407b0d40 = () => interopDefault(import('../pages/Account/LogoutModal.vue' /* webpackChunkName: "pages/Account/LogoutModal" */))
const _fac83996 = () => interopDefault(import('../pages/Account/ManageMarketingEmails.vue' /* webpackChunkName: "pages/Account/ManageMarketingEmails" */))
const _0b77bb02 = () => interopDefault(import('../pages/Account/MyOrdersReturnsApp.vue' /* webpackChunkName: "pages/Account/MyOrdersReturnsApp" */))
const _63a68426 = () => interopDefault(import('../pages/Account/MyVehicles.vue' /* webpackChunkName: "pages/Account/MyVehicles" */))
const _561494b9 = () => interopDefault(import('../pages/Account/OrderHistory.vue' /* webpackChunkName: "pages/Account/OrderHistory" */))
const _2ca9640a = () => interopDefault(import('../pages/Account/TrackMyOrder.vue' /* webpackChunkName: "pages/Account/TrackMyOrder" */))
const _49a3e88e = () => interopDefault(import('../pages/ActivateAccount.vue' /* webpackChunkName: "pages/ActivateAccount" */))
const _eb4b1596 = () => interopDefault(import('../pages/AmbassadorListing/index.vue' /* webpackChunkName: "pages/AmbassadorListing/index" */))
const _01de0ee0 = () => interopDefault(import('../pages/Brands/index.vue' /* webpackChunkName: "pages/Brands/index" */))
const _6064c547 = () => interopDefault(import('../pages/BuyingGuides/index.vue' /* webpackChunkName: "pages/BuyingGuides/index" */))
const _28a92257 = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "" */))
const _d78f36ba = () => interopDefault(import('../pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _32e7b6ae = () => interopDefault(import('../pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _328e6636 = () => interopDefault(import('../pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _1c1c31dd = () => interopDefault(import('../pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _42e791b2 = () => interopDefault(import('../pages/MediaLanding/index.vue' /* webpackChunkName: "" */))
const _07148da2 = () => interopDefault(import('../pages/ForgotPassword.vue' /* webpackChunkName: "pages/ForgotPassword" */))
const _3f7336aa = () => interopDefault(import('../pages/Healthcheck.vue' /* webpackChunkName: "pages/Healthcheck" */))
const _39def9f0 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _1fe52aa8 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _6b7e5958 = () => interopDefault(import('../pages/MediaListing/index.vue' /* webpackChunkName: "pages/MediaListing/index" */))
const _12bd9467 = () => interopDefault(import('../pages/MyVehicles/index.vue' /* webpackChunkName: "pages/MyVehicles/index" */))
const _0a6ed43c = () => interopDefault(import('../pages/Parts/index.vue' /* webpackChunkName: "pages/Parts/index" */))
const _52f2276e = () => interopDefault(import('../pages/Product.vue' /* webpackChunkName: "pages/Product" */))
const _48313ccc = () => interopDefault(import('../pages/ProjectCars/index.vue' /* webpackChunkName: "pages/ProjectCars/index" */))
const _4607fc7e = () => interopDefault(import('../pages/Pages/_slug.vue' /* webpackChunkName: "" */))
const _4fd0e8c9 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _1d3243f9 = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "pages/Search" */))
const _291a1769 = () => interopDefault(import('../pages/Signup.vue' /* webpackChunkName: "pages/Signup" */))
const _620c27db = () => interopDefault(import('../pages/Vehicles/index.vue' /* webpackChunkName: "pages/Vehicles/index" */))
const _266fb520 = () => interopDefault(import('../pages/Article/_slug.vue' /* webpackChunkName: "" */))
const _50fee875 = () => interopDefault(import('../pages/Video/_slug.vue' /* webpackChunkName: "" */))
const _39b21ddd = () => interopDefault(import('../pages/Ambassador/_slug.vue' /* webpackChunkName: "" */))
const _bd3b6d70 = () => interopDefault(import('../pages/Brands/_id.vue' /* webpackChunkName: "pages/Brands/_id" */))
const _5eae23ff = () => interopDefault(import('../pages/BuyingGuides/_slug.vue' /* webpackChunkName: "pages/BuyingGuides/_slug" */))
const _0ddc16cc = () => interopDefault(import('../pages/Parts/_slug.vue' /* webpackChunkName: "pages/Parts/_slug" */))
const _4b9e7f5c = () => interopDefault(import('../pages/ProjectCars/_slug.vue' /* webpackChunkName: "pages/ProjectCars/_slug" */))
const _43370e7c = () => interopDefault(import('../pages/Promotions/_slug.vue' /* webpackChunkName: "" */))
const _19172082 = () => interopDefault(import('../pages/Shop/_slug.vue' /* webpackChunkName: "" */))
const _cc056f54 = () => interopDefault(import('../pages/Vehicles/_make.vue' /* webpackChunkName: "pages/Vehicles/_make" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _4109d4a8,
    name: "Account___en",
    children: [{
      path: "account-details",
      component: _2038e468,
      name: "Account-AccountDetails___en"
    }, {
      path: "addresses",
      component: _c1b34942,
      name: "Account-Addresses___en"
    }, {
      path: "logout-modal",
      component: _407b0d40,
      name: "Account-LogoutModal___en"
    }, {
      path: "manage-marketing-emails",
      component: _fac83996,
      name: "Account-ManageMarketingEmails___en"
    }, {
      path: "my-orders-returns-app",
      component: _0b77bb02,
      name: "Account-MyOrdersReturnsApp___en"
    }, {
      path: "my-vehicles",
      component: _63a68426,
      name: "Account-MyVehicles___en"
    }, {
      path: "order-history",
      component: _561494b9,
      name: "Account-OrderHistory___en"
    }, {
      path: "track-my-order",
      component: _2ca9640a,
      name: "Account-TrackMyOrder___en"
    }]
  }, {
    path: "/activate-account",
    component: _49a3e88e,
    name: "ActivateAccount___en"
  }, {
    path: "/ambassador-listing",
    component: _eb4b1596,
    name: "AmbassadorListing___en"
  }, {
    path: "/brands",
    component: _01de0ee0,
    name: "Brands___en"
  }, {
    path: "/buying-guides",
    component: _6064c547,
    name: "BuyingGuides___en"
  }, {
    path: "/checkout",
    component: _28a92257,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _d78f36ba,
      name: "billing___en"
    }, {
      path: "payment",
      component: _32e7b6ae,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _328e6636,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _1c1c31dd,
      name: "thank-you___en"
    }]
  }, {
    path: "/checkout",
    component: _28a92257,
    name: "Checkout___en",
    children: [{
      path: "billing",
      component: _d78f36ba,
      name: "Checkout-Billing___en"
    }, {
      path: "payment",
      component: _32e7b6ae,
      name: "Checkout-Payment___en"
    }, {
      path: "shipping",
      component: _328e6636,
      name: "Checkout-Shipping___en"
    }, {
      path: "thank-you",
      component: _1c1c31dd,
      name: "Checkout-ThankYou___en"
    }]
  }, {
    path: "/content-hub",
    component: _42e791b2,
    name: "content-hub___en"
  }, {
    path: "/forgot-password",
    component: _07148da2,
    name: "ForgotPassword___en"
  }, {
    path: "/healthcheck",
    component: _3f7336aa,
    name: "Healthcheck___en"
  }, {
    path: "/home",
    component: _39def9f0,
    name: "Home___en"
  }, {
    path: "/login",
    component: _1fe52aa8,
    name: "Login___en"
  }, {
    path: "/media-landing",
    component: _42e791b2,
    name: "MediaLanding___en"
  }, {
    path: "/media-listing",
    component: _6b7e5958,
    name: "MediaListing___en"
  }, {
    path: "/my-vehicles",
    component: _12bd9467,
    name: "MyVehicles___en"
  }, {
    path: "/parts",
    component: _0a6ed43c,
    name: "Parts___en"
  }, {
    path: "/parts",
    component: _0a6ed43c,
    name: "parts___en"
  }, {
    path: "/product",
    component: _52f2276e,
    name: "Product___en"
  }, {
    path: "/project-cars",
    component: _48313ccc,
    name: "ProjectCars___en"
  }, {
    path: "/promotions",
    component: _4607fc7e,
    name: "promotions___en"
  }, {
    path: "/reset-password",
    component: _4fd0e8c9,
    name: "reset-password___en"
  }, {
    path: "/reset-password",
    component: _4fd0e8c9,
    name: "ResetPassword___en"
  }, {
    path: "/search",
    component: _1d3243f9,
    name: "Search___en"
  }, {
    path: "/signup",
    component: _291a1769,
    name: "Signup___en"
  }, {
    path: "/vehicles",
    component: _620c27db,
    name: "Vehicles___en"
  }, {
    path: "/content-hub/ambassador-picks",
    component: _eb4b1596,
    name: "ambassador-listing___en"
  }, {
    path: "/content-hub/articles-and-videos",
    component: _6b7e5958,
    name: "media-listing___en"
  }, {
    path: "/content-hub/articles-and-videos/articles/:slug",
    component: _266fb520,
    name: "article___en"
  }, {
    path: "/content-hub/articles-and-videos/videos/:slug",
    component: _50fee875,
    name: "video___en"
  }, {
    path: "/content-hub/ambassador-picks/:slug",
    component: _39b21ddd,
    name: "ambassador___en"
  }, {
    path: "/ambassador/:slug?",
    component: _39b21ddd,
    name: "Ambassador-slug___en"
  }, {
    path: "/article/:slug?",
    component: _266fb520,
    name: "Article-slug___en"
  }, {
    path: "/brands/:id",
    component: _bd3b6d70,
    name: "Brands-id___en"
  }, {
    path: "/buying-guides/:slug",
    component: _5eae23ff,
    name: "BuyingGuides-slug___en"
  }, {
    path: "/pages/:slug?",
    component: _4607fc7e,
    name: "Pages-slug___en"
  }, {
    path: "/parts/:slug",
    component: _0ddc16cc,
    name: "Parts-slug___en"
  }, {
    path: "/products/:slug",
    component: _52f2276e,
    name: "product___en"
  }, {
    path: "/project-cars/:slug",
    component: _4b9e7f5c,
    name: "ProjectCars-slug___en"
  }, {
    path: "/promotions/:slug",
    component: _43370e7c,
    name: "promotion___en"
  }, {
    path: "/promotions/:slug?",
    component: _43370e7c,
    name: "Promotions-slug___en"
  }, {
    path: "/shop/:slug",
    component: _19172082,
    name: "shop___en"
  }, {
    path: "/shop/:slug?",
    component: _19172082,
    name: "Shop-slug___en"
  }, {
    path: "/vehicles/:make",
    component: _cc056f54,
    name: "Vehicles-make___en"
  }, {
    path: "/video/:slug?",
    component: _50fee875,
    name: "Video-slug___en"
  }, {
    path: "/activate-account/:customerId/:activationToken",
    component: _49a3e88e,
    name: "activate-customer-account___en"
  }, {
    path: "/reset-password/:customerId/:resetToken",
    component: _4fd0e8c9,
    name: "reset-customer-password___en"
  }, {
    path: "/vehicles/:make/:model?/:year?/:series?",
    component: _cc056f54,
    name: "vehicles-route___en"
  }, {
    path: "/brands/:slug/:slug2?/:slug3?/:slug4?/:slug5?",
    component: _bd3b6d70,
    name: "brand-pages___en"
  }, {
    path: "/parts/:slug/:slug2?/:slug3?/:slug4?/:slug5?/:slug6?",
    component: _0ddc16cc,
    name: "parts-pages___en"
  }, {
    path: "/",
    component: _39def9f0,
    name: "home___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
